var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customVideo
    ? _c("UserVideo", {
        ref: "video",
        class: { "custom-video": _vm.isUsingCustomVideo },
        attrs: { track: _vm.customVideo },
      })
    : _c("div", { staticClass: "d-flex align-center" }, [_c("rtb-spinner")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <UserVideo
    v-if="customVideo"
    :track="customVideo"
    ref="video"
    :class="{ 'custom-video': isUsingCustomVideo }"
  />
  <div v-else class="d-flex align-center">
    <rtb-spinner />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import * as handTrack from "handtrackjs"
import Video from "twilio-video"
import { debounce, throttle } from "lodash"

import { RtbSpinner } from "@/components/ui"
import UserVideo from "@/components/GroupTeams/Common/User/UserVideo"

import HandMovementController from "./HandMovementController"

import Event from "./enums/Event"
import PredictionTypes from "./enums/PredictionTypes"

let model = null
let video = null

const modelParams = HandMovementController.modelObj.modelParams

export default {
  name: "VideoFeed",
  components: {
    UserVideo,
    RtbSpinner
  },
  props: {
    userID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      pred: null,
      customVideo: null,
      isUsingCustomVideo: false,
      state: null
    }
  },
  computed: {
    ...mapGetters(["gameStatus"]),
    ...mapGetters("twilio", ["users"]),
    ...mapGetters({ teams: "chats", currentTeamID: "teamID" }),
    myTeam() {
      return this.teams[this.currentTeamID] ?? {}
    },
    thisSelectedUserVideoTrack() {
      let videoTrack = this.userID
        ? this?.users?.[this.userID]?.videoTrack ?? null
        : null
      if (!videoTrack) return null
      return videoTrack
    },
    watchStartVideoValue() {
      return this.thisSelectedUserVideoTrack && !this.hasWinner
    },
    hasWinner() {
      return Object.values(this.teams).some(
        team => team?.handmovement?.completed
      )
    }
  },
  watch: {
    state: {
      handler: throttle(function (newValue, oldValue) {
        if (
          [
            PredictionTypes.CLOSED,
            PredictionTypes.POINT,
            PredictionTypes.PINCH,
            null
          ].includes(newValue)
        ) {
          if (oldValue === PredictionTypes.OPEN) {
            let score

            if (newValue === PredictionTypes.CLOSED) {
              score = 4.5
            } else if (newValue !== null) {
              score = 3.5
            } else {
              score = 0.4
            }

            this.$bus.$emit(Event.onNewScore, score)
          }
        }
      }, 500)
    },
    watchStartVideoValue: {
      handler: debounce(async function (value, oldValue) {
        if (!value) {
          this.isUsingCustomVideo = false
          video = model = undefined

          if (oldValue) {
            this.destroyHandTrack()
            console.log("destroyed handTrack video")
            this.customVideo = null
            setTimeout(() => {
              this.customVideo = this.thisSelectedUserVideoTrack
            }, 400)
          } else {
            this.customVideo = this.thisSelectedUserVideoTrack
          }
        } else {
          this.customVideo = null

          const _this = this

          if (!this.thisSelectedUserVideoTrack) return null

          const { mediaStreamTrack, dimensions } =
            this.thisSelectedUserVideoTrack

          if (!mediaStreamTrack) return

          video = document.createElement("video")
          video.width = dimensions.width
          video.height = dimensions.height

          const canvas = document.createElement("canvas")
          const context = canvas.getContext("2d")
          canvas.width = video.width
          canvas.height = video.height

          async function runDetection() {
            if (!model || !video) return

            try {
              const predictions = await model?.detect(video)

              context.clearRect(0, 0, canvas.width, canvas.height)

              context.save()

              if (modelParams.flipHorizontal && video) {
                context.scale(-1, 1)
                context.translate(-video.width, 0)
              }

              context?.drawImage(video, 0, 0, video.width, video.height)

              context?.restore()

              predictions.forEach(({ label, bbox }) => {
                if (label === PredictionTypes.FACE) return
                // console.log(label)

                if (Boolean(_this.gameStatus?.handMovementStarted)) {
                  _this.state = label
                }

                if (label === PredictionTypes.OPEN) {
                  context.drawImage(
                    _this._svg,
                    bbox?.[0],
                    bbox?.[1],
                    bbox?.[2],
                    bbox?.[3]
                  )
                }
              })
            } catch (e) {
              console.log(e)
            }

            requestAnimationFrame(runDetection)
          }

          // Load the model - (from cache).
          model = await HandMovementController.initializeModel()

          const [status, svg] = await Promise.all([
            HandMovementController.loadVideo(
              video,
              new MediaStream([mediaStreamTrack])
            ),
            HandMovementController.loadSvg()
          ])
          let videoTrack

          if (status) {
            console.log("Video started. Now tracking")

            this._svg = svg

            runDetection()

            const handTrackStream = canvas.captureStream()
            const [videoTrk] = handTrackStream.getTracks()

            videoTrack = Video.LocalVideoTrack(videoTrk)
            this.isUsingCustomVideo = true
          }
          this.customVideo = videoTrack ?? this.thisSelectedUserVideoTrack
        }
      }, 300),
      immediate: true
    },
    hasWinner(newValue, oldValue) {
      if (newValue === oldValue || !newValue) return
      if (newValue && !this.myTeam?.handmovement?.completed) {
        this.$bus.$emit(Event.onSubmit)
      }
    }
  },
  beforeDestroy() {
    this?.destroyHandTrack()
  },
  methods: {
    destroyHandTrack() {
      try {
        handTrack?.stopVideo(video)
        model = video = this.customVideo = undefined
      } catch (e) {}

      console.log("destroyed handTrack video")
    }
  }
}
</script>
<style>
.custom-video {
  transform: scale(1, 1) !important;
}
</style>
